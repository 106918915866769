
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import { ready } from 'thirdhome-styles/javascript/utils/ready.js'
import { setScrolled } from 'thirdhome-styles/javascript/utils/scroll.js'
import { toClipboard } from '../utils/toClipboard.js'
import { toggleMore } from '../utils/navigation.js'
import { passwordView } from 'thirdhome-styles/javascript/utils/passwords.js'
import { modalPop } from 'thirdhome-styles/javascript/utils/modals.js'
import { tabClick } from 'thirdhome-styles/javascript/utils/tabs.js'
import { removeItem } from 'thirdhome-styles/javascript/utils/remove.js'
import { initObserver, cannotObserve } from 'thirdhome-styles/javascript/utils/observer'


import Splide from '@splidejs/splide';
import Prism from 'prismjs';
import intlTelInput from 'intl-tel-input';

// CSS/SCSS
import "../stylesheets/application.scss"

// Images
require.context('../images', true)
// Include Shared Images
require.context('thirdhome-styles/images/', true, /\.(png|jpe?g|svg)$/)


document.addEventListener("turbolinks:load", function() {

  // Load Prism on page load
  Prism.highlightAll()

  // Track whether user has scrolled
  setScrolled()
  addEventListener('scroll', setScrolled)

  // Color copy to clipboard
  let clipEls = document.querySelectorAll('[data-clip]');
  if (clipEls.length) {
    [...clipEls].forEach( (clipEl) => {
      clipEl.addEventListener("click", toClipboard)
    })
  }

  // More Menu Toggle
  let moreMenu = document.querySelector(".more-link");
  moreMenu.addEventListener("click", toggleMore)
  
  // Card Sliders
  var elms = document.getElementsByClassName( 'splide' );
  for ( var i = 0, len = elms.length; i < len; i++ ) {
    new Splide( elms[ i ], {
      type: 'loop',
      perPage: 1,
      pagination: false,
      arrowPath: 'M20.1,40.2c-0.7,0-1.4-0.3-1.9-0.8L0.8,22C0.3,21.5,0,20.8,0,20.1s0.3-1.4,0.8-1.9L18.2,0.8c1.1-1.1,2.8-1.1,3.8,0   c1.1,1.1,1.1,2.8,0,3.8L6.6,20.1L22,35.5c1.1,1.1,1.1,2.8,0,3.8C21.5,39.9,20.8,40.2,20.1,40.2z'
    } ).mount();
  }

  // Icon Pill Toggle
  let iconPills = document.querySelectorAll('.icon-pill');
  if (iconPills.length) {
    [...iconPills].forEach( (iconPill) => {
      iconPill.addEventListener("click", function(event){
        event.preventDefault()
        iconPill.classList.toggle('active')
      })
    })
  }

  // Password View
  let toView = document.querySelectorAll('.pw-view');
  if (toView.length) {
    [...toView].forEach( (pwToView) => {
      pwToView.addEventListener("click", passwordView)
    })
  }

  // Tel Input
  const telInputs = document.querySelectorAll(".phone");
  if (telInputs.length) {
    [...telInputs].forEach( (telInput) => {
      intlTelInput(telInput, {
          // any initialization options go here
      })
    })
  }

  // Modal Open/Close
  const modalsOpen = document.querySelectorAll(".modal-pop");
  if (modalsOpen.length) {
    [...modalsOpen].forEach( (modalOpen) => {
      modalOpen.addEventListener("click", modalPop)
    })
  }

  // Disable Example Buttons
  let linkExamples = document.querySelectorAll('.framework-buttons-view .main-content a');
  if (linkExamples.length) {
    [...linkExamples].forEach( (links) => {
      links.addEventListener("click", function(event){
        event.preventDefault()
      })
    })
  }

  // Tabs
  const tabs = document.querySelectorAll(".tab-nav-item");
  if (tabs.length) {
    [...tabs].forEach( (tab) => {
      tab.addEventListener("click", tabClick)
    })
  }

  // Removable
  const removable = document.querySelectorAll(".removable .remove");
  if (removable.length) {
    [...removable].forEach( (removeIt) => {
      removeIt.addEventListener("click", removeItem)
    })
  }

  // Observer
  if (window.IntersectionObserver) {
    initObserver();
  } else {
    console.log("IntersectionObserver not supported.");
    cannotObserve();
  }

})
