export const toClipboard = (event) => {
  let clipText = event.target.getAttribute('data-clip')

  let tempInput = document.createElement("input")
  document.body.appendChild(tempInput)
  tempInput.value = clipText
  tempInput.select()
  document.execCommand("copy");
  document.body.removeChild(tempInput);

  alert("Copied: " + clipText);
  console.log(clipText)

}